/* eslint no-console: 0 */
import Vue from 'vue/dist/vue.esm'
import axios from "axios";
import { debounce } from "lodash";

import {
  sigFigs,
  currencyFormat,
  normalizeCurrency,
} from "../utils/formatting";


// https://gist.github.com/drozdzynski/de1584e3e16ea27f51798924b7da18a8
Vue.directive('init', {
  bind (el, binding, vnode, oldVnode) {
    var model = vnode.data.directives.find(function(dir) { return dir.name === 'model' });
    if (!model || !model.expression) {
      console.error('[vue-set-value warn] The element does not have v-model');
      return;
    }
    const expressions = model.expression.split(".");
    let context = vnode.context;
    for(let i = 0; i < (expressions.length-1); i++) {
      context = context[expressions[i]]
    }
    context[expressions[expressions.length-1]] = binding.value;
  }
});

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    components: {
    },
    data: {
      refCurrency: null,
      auctionAmount: null,
      auctionCurrency: null,
      buyerAmount: null,
      buyerCurrency: null,
      currencyRatesLoaded: false,
      masterField: "auctionAmount",
    },
    methods: {
      switchMasterFieldToAuctionAmount: function() {
        this.masterField = "auctionAmount";
      },
      switchMasterFieldToBuyerAmount: function() {
        this.masterField = "buyerAmount";
      },
      setMinimumAuctionAmount: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode("USD")[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          this.auctionAmount = this.sigFigs(this.convert(500, currencyFrom, currencyTo), 1);
        } else {
          return 0;
        }
      },
      convert: function(amountFrom, currencyFrom, currencyTo) {
        const currencyFromNormalized = this.normalizeCurrency(currencyFrom);
        const currencyToNormalized = this.normalizeCurrency(currencyTo);
        return amountFrom*this.currencyRates[currencyFromNormalized]/this.currencyRates[currencyToNormalized];
      },
      loadCurrencyRates: function() {
        const vm = this;

        axios.get("/api_v1/current_rates.json", {
          headers: {
            'Content-Type': "application/json; charset=utf-8"
          }
        })
        .then(function (response) {
          vm.currencyRatesBase = response.data.base;
          vm.currencyRates = response.data.rates;
          vm.currencyRatesLoaded = true;

          if (!vm.auctionAmount) vm.setMinimumAuctionAmount();
          vm.debouncedRefreshSlaveAmount();
        })
        .catch(function (error) {
          console.log('Error! Could not reach the API. ' + error)
        });
      },
      refreshSlaveAmount: function() {
        if ( this.masterField == "auctionAmount" ) {
          const currencyFrom = toCurrencyCode(this.auctionCurrency)[0];
          const currencyTo = toCurrencyCode(this.buyerCurrency)[0];
          this.buyerAmount = this.sigFigs(this.convert(this.auctionAmount, currencyFrom, currencyTo), 4);
        } else {
          const currencyFrom = toCurrencyCode(this.buyerCurrency)[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          this.auctionAmount = this.sigFigs(this.convert(this.buyerAmount, currencyFrom, currencyTo), 4);
        }
      },
      switchCurrencies: function(e) {
        const element = e.currentTarget;
        if (e) e.preventDefault();

        e.currentTarget.classList.add("rotate");
        setTimeout(function () {
          element.classList.remove("rotate");
        },500);

        const auctionCurrencyWas = this.auctionCurrency;
        this.auctionCurrency = this.buyerCurrency;
        this.buyerCurrency = auctionCurrencyWas;

        this.$nextTick(function(){ $(e.currentTarget.form).find('.selectpicker').selectpicker('refresh'); });
      },
      validateAndSubmit: function(e) {
        const form = e.currentTarget.form;
        if (form.checkValidity()) {
          if (e) e.preventDefault();
          this.askDistinationsSign();
        } else {
          form.reportValidity();
        }
      },
      ...{ sigFigs, currencyFormat, normalizeCurrency },
    },
    computed: {
      auctionCurrencyRateFormatted: function() {
        if ( this.auctionCurrency ) {
          return "1 " + this.currencyFormat(this.auctionCurrency,false);
        }
      },
      auctionAmountInRefCurrency: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode(this.auctionCurrency)[0];
          const currencyTo = toCurrencyCode(this.refCurrency)[0];
          return this.sigFigs(this.convert(this.auctionAmount, currencyFrom, currencyTo), 4);
        } else {
          return NaN;
        }
      },
      auctionAmountInRefCurrencyFormatted: function() {
        if ( !isNaN(this.auctionAmountInRefCurrency) ) {
          return "~" + this.sigFigs(this.auctionAmountInRefCurrency, 4) + " " + this.currencyFormat(this.refCurrency, false);
        } else {
          return "-";
        }
      },
      auctionCurrencyRatesInBuyerCurrency: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode(this.auctionCurrency)[0];
          const currencyTo = toCurrencyCode(this.buyerCurrency)[0];
          return this.sigFigs(this.convert(1, currencyFrom, currencyTo), 4);
        } else {
          return NaN;
        }
      },
      auctionCurrencyRatesInBuyerCurrencyFormatted: function() {
        if ( !isNaN(this.auctionCurrencyRatesInBuyerCurrency) ) {
          return this.sigFigs(this.auctionCurrencyRatesInBuyerCurrency, 4) + " " + this.currencyFormat(this.buyerCurrency, false);
        } else {
          return "-";
        }
      },
      buyerAmountInRefCurrency: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode(this.buyerCurrency)[0];
          const currencyTo = toCurrencyCode(this.refCurrency)[0];
          return this.sigFigs(this.convert(this.buyerAmount, currencyFrom, currencyTo), 4);
        } else {
          return NaN;
        }
      },
      buyerAmountInRefCurrencyFormatted: function() {
        if ( !isNaN(this.buyerAmountInRefCurrency) ) {
          return "~" + this.sigFigs(this.buyerAmountInRefCurrency, 4) + " " + this.currencyFormat(this.refCurrency, false);
        } else {
          return "-";
        }
      },
      minAuctionAmountLimit: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode("USD")[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          return this.sigFigs(this.convert(50, currencyFrom, currencyTo), 4);
        } else {
          return 0;
        }
      },
      maxAuctionAmountLimit: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode("USD")[0];
          const currencyTo = toCurrencyCode(this.auctionCurrency)[0];
          return this.sigFigs(this.convert(1000000, currencyFrom, currencyTo), 4);
        } else {
          return 0;
        }
      },
      minBuyerAmountLimit: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode("USD")[0];
          const currencyTo = toCurrencyCode(this.buyerCurrency)[0];
          return this.sigFigs(this.convert(50, currencyFrom, currencyTo), 4);
        } else {
          return 0;
        }
      },
      maxBuyerAmountLimit: function() {
        if ( this.currencyRatesLoaded ) {
          const currencyFrom = toCurrencyCode("USD")[0];
          const currencyTo = toCurrencyCode(this.buyerCurrency)[0];
          return this.sigFigs(this.convert(1000000, currencyFrom, currencyTo), 4);
        } else {
          return 0;
        }
      }
    },
    watch: {
      auctionAmount: function() {
        if ( this.currencyRatesLoaded ) {
          this.debouncedRefreshSlaveAmount();
        }
      },
      auctionCurrency: function() {
        if ( this.currencyRatesLoaded ) {
          this.debouncedRefreshSlaveAmount();
        }
      },
      buyerAmount: function() {
        if ( this.currencyRatesLoaded ) {
          this.debouncedRefreshSlaveAmount();
        }
      },
      buyerCurrency: function() {
        if ( this.currencyRatesLoaded ) {
          this.debouncedRefreshSlaveAmount();
        }
      },
    },
    mounted() {
      var vm = this;
      vm.loadCurrencyRates();

      this.debouncedRefreshSlaveAmount = _.debounce(this.refreshSlaveAmount, 100);

      setInterval(this.debouncedLoadEstimateFee, 60000);
    }
  }).$mount('#exchange-placeholder')
})
