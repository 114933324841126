const sigFigs = (n, sig, method="round") => {
  if (n > 0 ) {
    if (n < Math.pow(10,n)) {
      var powN = sig - Math.floor(Math.log(n) / Math.LN10) - 1;
      var mult = Math.pow(10, powN);
      if (method == "ceil") return Math.ceil(n * mult) / mult;
      else return Math.round(n * mult) / mult;
    } else {
      if (method == "ceil") return Math.ceil(n);
      else return Math.round(n);
    }
  } else {
    return 0;
  }
};

const sigFigsString = (n, sig, method="round") => {
  if (n > 0 ) {
    if (n < Math.pow(10,n)) {
      var powN = sig - Math.floor(Math.log(n) / Math.LN10) - 1;
      var mult = Math.pow(10, powN);
      if (method == "ceil") return (Math.ceil(n * mult) / mult).toFixed(powN);
      else return (Math.round(n * mult) / mult).toFixed(powN);
    } else {
      if (method == "ceil") return Math.ceil(n);
      else return Math.round(n);
    }
  } else {
    return 0;
  }
};

const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

const currencyFormat = (currencyCode, withNetworkInfo=true) => {
  if ( withNetworkInfo ) {
    const coinTypeInfo = splitOnLast(currencyCode, '-');
    const splitedCurrency = toCurrencyCode(currencyCode);
    let formattedCode = '<span class="curr-format">' + splitedCurrency[0];

    if ( coinTypeInfo.length > 1 ) {
      formattedCode += '<span class="supsub"><sup class="superscript">' + splitedCurrency[1] + '</sup>';
      if ( splitedCurrency.length > 2 ) {
        formattedCode += '<sub class="subscript">' + splitedCurrency[2] + '</sub>';
      }

      formattedCode += '</span>';
    } else if ( splitedCurrency.length > 1 ) {
      formattedCode += '<span class="supsub"><sub class="subscript">' + splitedCurrency[1] + '</sub></span>';
    }

    formattedCode += '</span>';

    return formattedCode;
  } else {
    const splitedCurrency = toCurrencyCode(currencyCode);
    return splitedCurrency[0];
  }
};

const normalizeCurrency = (currency) => {
  var currencyToReturn = currency;
  switch (currency) {
    case 'BSC-USD':
      return 'USDT';
    case 'WBNB':
      return 'BNB';
    case 'BETH':
      return 'ETH';
    case currency.match(/^v/)?.input:
      return currency.replace(/^v/, '');
    default:
      return currency;
  }

  currencyToReturn = currencyToReturn.replace('BSC-', '');
  return currencyToReturn;
}

export { sigFigs, sigFigsString, currencyFormat, normalizeCurrency, nFormatter };
